.fullcalendar-item-container {
  flex-direction: row !important;
  justify-content: space-between;
  padding: 5px;
  background-color: #3788d8;
}

.fc .fc-daygrid-event {
  z-index: 6;
  /* margin-top: 1px; */
  border-width: 2px;
  border-color: #1c4067;
  /* background: #3788d8; */
}

.activity-calendar-status-flag {
  margin-left: '5px';
  margin-top: '2px';
}

.fc-event-title {
  color: #ffff;
}

.fc-event-time {
  color: #ffff;
}

.fc-timegrid-event-harness {
  height: 70px;
}