.dataGridWithFilter .MuiDataGrid-columnHeader {
  background-color: #f4f6f8;
}

.dataGridWithFilter .MuiDataGrid-cell {
  border: 0 !important;
}

.dataGridWithFilter .resultsPerPage .MuiSelect-icon {
  display: none;
}

.dataGridWithFilter
  .resultsPerPage
  [class*='-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input'] {
  padding: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.dataGridWithFilter .paginationInput [class*='-MuiInputBase-input-MuiOutlinedInput-input'] {
  padding: 0 !important;
  text-align: center;
}
