.patient-header-table-th {
  padding: 5px;
  text-align: right;
  width: 60px;
  padding-right: 15px;
};

.patient-header-table-td {
  padding: 5px;
  text-align: left;
};