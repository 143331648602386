svg.fillColor path {
  fill: currentColor !important;
}

@keyframes shine {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
    box-shadow: 0 0 20px #fff;
  }
  100% {
    opacity: 0.6;
    transform: scale(1);
  }
}

.shining-icon-button {
  animation: shine 2s infinite ease-in-out;
}

.pointerRow .MuiDataGrid-row:hover {
  cursor: pointer;
}
